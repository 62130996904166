import React, { useState, useRef } from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";

const MspiLanding2 = () => {
  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout>
        <SEO
          title="2 Miss Singapore Pageant International - Audition is now open!"
          description="Miss Singapore International will represent Singapore on an International platform with 84 contestants around the world. The exchanges and exposure aim to bring forth more women to anchor a role in the society with a charitable approach, grace, integrity and honour."
          image="https://res.cloudinary.com/dkm14ge3g/image/upload/v1638588570/Logo/qiuk4zzjcgmidemdqavw.webp"
          imageAlt="Miss Singapore International Pageant | Application"
        />

        <div className="bg-white mb-28">
          <main className="isolate">
            {/* Hero section */}
            <div className="relative pt-14">
              <div className="py-6 sm:py-12">
                <div className="absolute inset-x-0 overflow-hidden -top-40 -z-10 transform-gpu blur-3xl sm:-top-80">
                  <svg
                    className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
                    viewBox="0 0 1155 678"
                  >
                    <path
                      fill="url(#9b2541ea-d39d-499b-bd42-aeea3e93f5ff)"
                      fillOpacity=".3"
                      d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                    />
                    <defs>
                      <linearGradient
                        id="9b2541ea-d39d-499b-bd42-aeea3e93f5ff"
                        x1="1155.49"
                        x2="-78.208"
                        y1=".177"
                        y2="474.645"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#9089FC" />
                        <stop offset={1} stopColor="#FF80B5" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div className="px-6 mx-auto max-w-7xl lg:px-8">
                  <div className="max-w-2xl mx-auto text-start">
                    <h1 className="text-4xl font-bold tracking-tight text-center text-swa-3 sm:text-6xl">
                      Miss Singapore Pageant International
                    </h1>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                      We believe beauty is more than skin deep and that true
                      beauty comes from within. Our unique proposition is the
                      promotion of critical virtues like charity, goodwill, and
                      grace. We pride ourselves on grooming young women to be
                      female ambassadors and pillars of society.
                    </p>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                      At Miss International, personality plays a more
                      significant role than looks. We focus on building
                      character and promoting the values of sharing and giving
                      back to society. Our Miss International Foundation
                      supports underprivileged children through various
                      beneficiaries like UNICEF and other charity institutions.
                    </p>
                    <div className="flow-root my-12 sm:my-16">
                      <div className="p-2 -m-2 rounded-xl bg-gray-900/5 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
                        <img
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1678507823/mspiPage/utt1uik8ql3qyzkezhi2.webp"
                          alt="MSPI"
                          width={1000}
                          height={700}
                          className="mx-auto rounded-md shadow-2xl ring-1 ring-gray-900/10"
                        />
                      </div>
                    </div>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                      Our pageant isn't just about winning a crown. It's about
                      embarking on a personal journey to be involved in charity
                      and paying it forward with your skills and talents. Our
                      past Miss Internationals have become mentors in their
                      fields and have made a difference in their communities.
                    </p>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                      As Miss Singapore International, you will represent
                      Singapore on an international platform with 84 contestants
                      worldwide. You'll have the opportunity to exchange and
                      learn from others and to bring forth more women to anchor
                      a role in society with a charitable approach, grace,
                      integrity, and honour. We hope that future generations of
                      women will follow in your footsteps and play a part in
                      holding the flag high by extending themselves in their
                      lifetime.
                    </p>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                      To join us at Miss International and become a part of our
                      legacy of beauty, grace, and giving back to society, click
                      the button below to register today!
                    </p>

                    <div className="flow-root mt-12 sm:my-16">
                      <div className="p-2 -m-2 rounded-xl bg-gray-900/5 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
                        <img
                          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1678457111/mspiPage/wr5uzn3klmzz4et0cbpq.png"
                          alt="MSPI"
                          width={1000}
                          height={700}
                          className="mx-auto rounded-md shadow-2xl ring-1 ring-gray-900/10"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </Layout>
    </>
  );
};

export default MspiLanding2;
